import React from "react";
import Century from "../../../assets/img/partners/century.jpeg";
import Petrovn from "../../../assets/img/partners/petrovn.jpeg";
import Vnpaco from "../../../assets/img/partners/vinapaco.jpeg";
import Vnsteel from "../../../assets/img/partners/vnsteel.jpeg";
import Airliquide from "../../../assets/img/partners/airliquide.jpeg";

const Partner = () => {
  return (
    <section className="container mx-auto py-12 text-center">
      <h2 className="mb-8 text-5xl font-bold leading-10 md:text-6xl">
        CÁC ĐỐI TÁC KHÁCH HÀNG
      </h2>
      {/* Line break */}
      <div className="mb-6 flex items-center justify-center">
        <div className="w-1/4 border-2 border-t border-black"></div>
        <div className="mx-4">
          <div className="h-5 w-24 rounded-full bg-black"></div>
        </div>
        <div className="w-1/4 border-2 border-t border-black"></div>
      </div>
      <div className="flex items-center justify-evenly px-10">
        {/* Repeat this div for each partner logo placeholder */}
        <div className="h-36 w-44">
          <img src={Century} alt="Century" className="h-full w-full" />
        </div>
        <div className="bg-gray-300 h-32 w-32">
          <img src={Petrovn} alt="Petrovn" className="h-full w-full" />
        </div>
        <div className="bg-gray-300 h-32 w-32">
          <img src={Vnpaco} alt="Vnpaco" className="h-full w-full" />
        </div>
        <div className="bg-gray-300 h-32 w-32">
          <img src={Vnsteel} alt="Vnsteel" className="h-full w-full" />
        </div>
        <div className="bg-gray-300 h-32 w-32">
          <img src={Airliquide} alt="Airliquide" className="h-full w-full" />
        </div>
      </div>
    </section>
  );
};

export default Partner;
